import { TimesplitId } from '~/types/TimesplitId';
import React from 'react';
import localforage from 'localforage';
import { summaryDataCacheKey } from '~/constants/cache';
import TimesplitAndPattern from './TimesplitAndPattern';
import FirstOvernight from './FirstOvernight';
import Dates from './Dates';
import OvernightPercent from './OvernightPercent';
import { ScheduleSummary as ScheduleSummaryInterface } from '~/interfaces/ScheduleSummary';

export default function ScheduleSummary() {
  const [summaryData, setSummaryData] = React.useState<ScheduleSummaryInterface | undefined>(undefined);

  React.useEffect(() => {
    const fetchSummaryData = async () => {
      const data = (await localforage.getItem(summaryDataCacheKey)) as ScheduleSummaryInterface;
      setSummaryData(data);
    };

    fetchSummaryData();
  }, []);

  if (!summaryData) return null;

  return (
    <div className="summary p-[2px]">
      <div className="flex min-w-80 max-w-96 flex-col">
        <div className="flex flex-col rounded-[1rem] bg-beige-light-20 py-4">
          <h4 className="flex items-center justify-center text-sm font-bold uppercase text-brown">Summary</h4>
          <div className="mt-4 w-full border-b border-taupe" />
          <TimesplitAndPattern
            timesplitId={summaryData.timesplitId as TimesplitId}
            templateName={summaryData.templateName}
          />
          <span className="mx-4 flex border-b border-dashed border-taupe pt-4" />
          <FirstOvernight
            firstOvernight={summaryData.firstOvernight}
            parentName={summaryData.parentName}
            coparentName={summaryData.coparentName}
          />
          <Dates startDate={summaryData.startDate} endDate={summaryData.endDate} />
          <OvernightPercent overnightStats={summaryData?.overnightStats} />
        </div>
      </div>
    </div>
  );
}
