import { ID } from '@segment/analytics-next';
import React from 'react';
import { fireFeedbackFormSubmitted } from '~/analytics/segmentTrack';
import { FeedbackFormData } from '~/interfaces/FeedbackFormData';

export default function useSubmitTally({ anonymousId, email }: { anonymousId: ID; email?: string }) {
  const handleSubmitFeedback = React.useCallback(
    (e: MessageEvent) => {
      if (typeof e?.data === 'string' && e?.data?.includes('Tally.FormSubmitted')) {
        const payload = JSON.parse(e.data).payload as FeedbackFormData;
        fireFeedbackFormSubmitted({ ...payload, email, anonymousId: anonymousId || '' });
      }
    },
    [anonymousId, email]
  );

  React.useEffect(() => {
    window.addEventListener('message', handleSubmitFeedback);

    return () => {
      window.removeEventListener('message', handleSubmitFeedback);
    };
  }, [anonymousId, handleSubmitFeedback]);
}
