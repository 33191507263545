import { PageRoutes } from '~/constants/routes';
import { DropdownMenuItem } from '../ui/dropdown-menu';
import { Link } from '@remix-run/react';
import ProfileIcon from '../icons/ProfileIcon';
import CaretRightIcon from '../icons/CaretRightIcon';
import SecurityIcon from '../icons/SecurityIcon';
import CalendarIcon from '../icons/CalendarIcon';
import CalendarCheckIcon from '../icons/CalendarCheckIcon';
import SummaryIcon from '../icons/SummaryIcon';
import { Button } from '../ui/button';
import HolidaysIcon from '../icons/HolidaysIcon';
import { cn } from '~/lib/utils';
import ShareIcon from '../icons/ShareIcon';
import React from 'react';

export default function AuthedMenu({
  onCloseMenu,
  openSummary,
  scheduleExists,
}: {
  onCloseMenu: () => void;
  openSummary: () => void;
  scheduleExists: boolean;
}) {
  const menuItems = [
    { route: PageRoutes.PROFILE, icon: <ProfileIcon />, label: 'Profile', extraClassName: 'pt-4' },
    { route: PageRoutes.SECURITY, icon: <SecurityIcon />, label: 'Security' },
    ...(scheduleExists ? [{ route: PageRoutes.CALENDAR_PREVIEW, icon: <CalendarIcon />, label: 'My calendar' }] : []),
    ...(scheduleExists
      ? [
          { onClick: () => openSummary(), icon: <SummaryIcon />, label: 'Summary' },
          { route: PageRoutes.HOLIDAYS_LIST, icon: <HolidaysIcon />, label: 'Holidays' },
          { route: PageRoutes.CALENDAR_SHARING, icon: <ShareIcon />, label: 'Calendar sharing' },
        ]
      : []),
    { route: PageRoutes.TEMPLATES, icon: <CalendarCheckIcon />, label: 'Choose new template' },
  ];

  return (
    <>
      {menuItems.map(({ icon, label, extraClassName, route, onClick }) => {
        const content = <MenuItemContent icon={icon} label={label} />;
        const isSecurity = route === PageRoutes.SECURITY;

        return (
          <React.Fragment key={label}>
            <DropdownMenuItem onClick={onCloseMenu} className="flex w-full focus:bg-transparent">
              {onClick ? (
                <Button type="button" onClick={onClick} className="flex w-full items-center justify-between px-0 py-2">
                  {content}
                  <CaretRightIcon />
                </Button>
              ) : (
                <MenuLink route={route} extraClassName={extraClassName}>
                  {content}
                </MenuLink>
              )}
            </DropdownMenuItem>
            {isSecurity ? <span className="mb-2 flex w-full border-b border-dashed border-taupe pt-2" /> : null}
          </React.Fragment>
        );
      })}
      <div className="mt-2 w-full border-t border-dashed border-taupe pb-2" />
    </>
  );
}

const MenuItemContent = ({ icon, label }: { icon: React.ReactNode; label: string }) => (
  <span className="flex items-center justify-start space-x-2">
    <span className="relative top-[-1px]">{icon}</span>
    <span className="text-lg font-semibold text-green-primary-text">{label}</span>
  </span>
);

const MenuLink = ({
  children,
  extraClassName,
  route,
}: {
  children: React.ReactNode;
  extraClassName?: string;
  route: PageRoutes;
}) => {
  const baseClasses = 'flex w-full items-center justify-between py-2';
  return (
    <Link to={route} className={cn(baseClasses, extraClassName)}>
      {children}
      <CaretRightIcon />
    </Link>
  );
};
