declare global {
  interface Window {
    Tally: {
      closePopup: (id: string) => void;
      openPopup: (id: string, options: unknown) => void;
    };
  }
}

// https://tally.so/help/developer-resources#2f676e40530a460ea6a634b8441f6001
export function showTally({ formId, onClose = () => {} }: { formId: string; onClose?: () => void }) {
  window.Tally.openPopup(formId, {
    key: formId,
    hideTitle: true,
    layout: 'default',
    doNotShowAfterSubmit: false,
    onClose: onClose,
    onSubmit: () => {},
  });
}

export function closeTally({ formId }: { formId: string }) {
  window.Tally.closePopup(formId);
}
